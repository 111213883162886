@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Nav Css Start */
.custom-nav {
  background-color: #000037 !important;
}

.custom-nav .container-fluid {
  margin-left: 14% !important;
  margin-right: 3%;
}

.custom-nav a.nav-link {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  padding: 0px 15px !important;
}

.custom-nav a.nav-link.active {
  color: #00aaff;
}

.custom-nav .dropdown {
  right: 0px;
}

.custom-nav .dropdown button {
  background: none;
  border: 1px solid #fff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
}

.custom-nav .dropdown button::after {
  display: none;
}

.custom-nav ul li.socialLinks {
  margin-left: 25px;
}

/* Nav Css End */

/* Slider Css Start */

section.Slider img {
  height: 800px;
  object-fit: cover;
}

/* Slider Css End */

/* Slider Css Start */
.Sale {
  background-repeat: no-repeat;
  background-color: #080b2d;
  background-size: cover;
  background-position: bottom;
  padding: 60px 0px;
  background-image: url(../../../public/assets/images/hotesalebg.png);
}

.hotsalebg {
  background-image: url(../../../public/assets/images/layersales.png);
  background-size: cover;
  width: 100%;
}

/* section.Sale .banner {
    background-image: url(../../../public/assets/images/presell.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 800px;
    padding: 20px;
} */

/* section.Sale .salecontent {
    position: absolute;
    border-radius: 8px;
    padding: 0px 35px;
    left: 50%;
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 100%;
    top: 0px;
} */
.salecontent h3 {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 100%;
}

.salecontent h4 {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 100%;
  margin-top: 20px;
}

.salecontent span {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.salecontent li {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  color: #000000;
}

.salecontent input::placeholder {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  color: #000000;
}

.salecontent a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #ffffff;
}

.solution-content {
  background: linear-gradient(
    90deg,
    rgba(17, 0, 84, 1) 0%,
    rgba(34, 24, 114, 1) 30%,
    rgba(44, 38, 130, 1) 100%
  );
  padding: 40px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.solution-inner {
  background-image: url(../../../public/assets/images/coinbg.png);
}

.banner-sale {
  position: relative;
  border: 1px solid transparent;
  border-radius: 16px;
  background: linear-gradient(to right, #07091f, #0c213c);
  background-clip: padding-box;
  padding: 40px;
}

.banner-sale::after {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  background: linear-gradient(#9134ee, #6b91e0);
  content: "";
  z-index: -1;
  border-radius: 16px;
}

section.Sale .salecontent input {
  padding-left: 25px;
}

section.Sale .salecontent input::placeholder {
  font-weight: 500;
  font-size: 18px;
}

section.Sale .countdown h1 {
  border: 1px dashed #00aaff;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  width: 65px;
  height: 65px;
  margin: 0px;
  font-size: 34px;
  color: #00aaff;
  font-weight: 500;
  margin-bottom: 15px;
}

section.Sale .countdown h4 {
  font-size: 18px;
  color: #00aaff;
  font-weight: 500;
  margin-bottom: 20px;
}

section.Sale .countdown > div {
  margin-right: 20px;
}

section.Sale .banner span {
  color: #fff;
}

ul.coins li {
  background: #ffffff;
  border-radius: 6px;
  padding: 9px;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 5px 10px;
}

/* Slider Css End */

/* AboutUs Css Start */
section.AboutUs {
  background-image: url(../../../public/assets/images/aboutusback.png);
  background-repeat: no-repeat;
  /* height: 800px; */
  padding: 150px 0px 150px;
  background-color: #080b2d;
  background-size: cover;
}

/* AboutUs Css End */

/* Problems Css Start */
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -115px !important;
  margin-top: var(--bs-dropdown-spacer);
}
section.Problems {
  background-image: url(../../../public/assets/images/problemback.png);
  background-repeat: no-repeat;
  /* height: 800px; */
  /* background: linear-gradient(105.55deg, rgba(220, 31, 255, 0.1) -53%, rgba(39, 170, 225, 0.1) 100%); */
  background-color: #080b2d;
  padding: 150px 0px;

  background-size: cover;
}

section.Problems .ListCount {
  background-image: url(../../../public/assets/images/ListFrame.png);
  background-repeat: no-repeat;
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

section.Problems .ListCount .List {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 100%;
  color: #ffffff;
  margin: 0px;
}

/* section.Problems .banner {
    background-image: url(../../../public/assets/images/frame.png);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 40px;
} */
/* Problems Css End */
/* Solutions Css Start */
section.Solutions {
  /* background-image: url(../../../public/assets/images/solutionback.png);
  background-repeat: no-repeat;
  background-size: cover; */
}
.cus-input::placeholder{
color: grey!important;
}
/* section.Solutions .banner {
    background-image: url(../../../public/assets/images/SolutionRectangle.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;
    height: 844px;
} */
.solution-banner {
  position: relative;
  border: 1px solid transparent;
  border-radius: 16px;
  background: black;
  background-clip: padding-box;
  padding: 40px;
}

.solution-banner::after {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  background: linear-gradient(#9134ee, #6b91e0);
  content: "";
  z-index: -1;
  border-radius: 16px;
}

/* section.Solutions .Box {
    background-image: url(../../../public/assets/images/solutionframe.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;
} */

section.Solutions .BannerContent {
  border-radius: 8px;
  background-color: #00aaff;
  padding: 15px;
}

/* Solutions Css End */

/* Buy Css Start */
section.Buy {
  background: linear-gradient(
    105.55deg,
    rgba(220, 31, 255, 0.1) -53%,
    rgba(39, 170, 225, 0.1) 100%
  );
  background-color: #080b2d;
  padding: 150px 0px;
  background-image: url(../../../public/assets/images/buyback.png);
}

/* section.Buy .Box {
    background-image: url(../../../public/assets/images/BuyRectangle.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;
    height: 488px;
} */

section.Buy .Box img {
  max-width: 90px;
  height: 90px;
}

/* Buy Css End */

section.RoadMap {
  background: linear-gradient(
    105.55deg,
    rgba(220, 31, 255, 0.1) -53%,
    rgba(39, 170, 225, 0.1) 100%
  );
  background-color: #080b2d;
  padding: 150px 0px;
  background-image: url(../../../public/assets/images/RoadMap.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.blueheadingtwo {
  font-family: "Prompt" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 44px !important;
  line-height: 100% !important;
  /* identical to box height, or 44px */

  color: #00aaff;
}

.whiteheadingtwoc {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
}

.blueheadingtwoc {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 140%;

  color: #00aaff;
}

.timeline {
  position: relative;
  overflow: hidden;
}

.timeline:before {
  content: "";
  position: absolute;
  top: 10px;
  left: 50%;
  margin-left: -1px;
  height: 98%;
  z-index: 1;
  border: 1px dashed #00aaff;
}

.timeline-block {
  width: -webkit-calc(50% + 8px);
  width: -moz-calc(50% + 8px);
  width: calc(50% + 8px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  /* direction: rtl; */
}

.timeline-block-left .timeline-content .data {
  display: flex;
  flex-direction: column;
  padding-left: 100px;
  padding-right: 200px;
  width: 100%;
  height: 100%;
  justify-content: center;
  /* position: absolute;
    top: 25%;
    z-index: 9999;
    left: 280px;
    -moz-transform: translate(-65%, 0);
    -ms-transform: translate(-65%, 0);
    -o-transform: translate(-65%, 0);
    -webkit-transform: translate(-65%, 0);
    transform: translate(-65%, 0); */
}

.timeline-block-right .timeline-content .data {
  display: flex;
  flex-direction: column;
  padding-left: 100px;
  padding-right: 190px;
  width: 100%;
  height: 100%;
  justify-content: center;
  /* position: absolute;
    bottom: 27px;
    z-index: 9999;
    left: 50%;
    -moz-transform: translate(-65%, 0);
    -ms-transform: translate(-65%, 0);
    -o-transform: translate(-65%, 0);
    -webkit-transform: translate(-65%, 0);
    transform: translate(-65%, 0); */
}

.timeline-block-right .timeline-content {
  background-image: url(../../../public/assets/images/Union.png);
  background-repeat: no-repeat;
  height: 186px;
  width: 100%;
  /* text-align: center; */
  /* padding-top: 40px; */
  position: relative;
  left: 70px;
}

.timeline-block-right .timeline-content {
  position: relative;
  /* border: 1px solid transparent; */
  /* border-radius: 16px; */
  /* background: #07071C;
    background-clip: padding-box; */
  height: 186px;
  /* width: 76%; */
  /* text-align: center; */
  /* padding-top: 40px; */
  position: relative;
  /* left: -67px; */
  /* right: 153px; */
}

.timeline-block-right .timeline-content::after {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  /* background: linear-gradient(#9134ee, #6b91e0); */
  content: "";
  z-index: -1;
  border-radius: 16px;
}

.timeline-block-right .marker {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* border: 2px solid #f5f7fa; */
  background: #00aaff;
  margin-top: 10px;
  z-index: 9999;
  position: relative;
  left: -2px;
}

.timeline-block-right .marker::after {
  content: "";
  border: 1px solid #27aae1;
  width: 40px;
  display: block;
  height: 40px;
  border-radius: 100%;
  position: relative;
  top: -10px;
  right: 10px;
}

.timeline-block-left .timeline-content {
  background-image: url(../../../public/assets/images/UnionLeft.png);
  background-repeat: no-repeat;
  height: 186px;
  width: 100%;
  text-align: start;
  position: relative;
}

.timeline-block-left .timeline-content::after {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  /* background: linear-gradient(#9134ee, #6b91e0); */
  content: "";
  z-index: -1;
  border-radius: 16px;
}

.timeline-block-left .marker {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #00aaff;
  margin-top: 10px;
  z-index: 9999;
  position: relative;
  right: -2px;
}

.timeline-block-left .marker::after {
  content: "";
  border: 1px solid #27aae1;
  width: 40px;
  display: block;
  height: 40px;
  border-radius: 100%;
  position: relative;
  top: -10px;
  right: 10px;
}

.content-card {
  position: relative;
  border: 1px solid transparent;
  border-radius: 16px;
  background: black;
  background-clip: padding-box;
  padding: 40px;
  height: 600px;
}

.content-card::after {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  background: linear-gradient(#9134ee, #6b91e0);
  content: "";
  z-index: -1;
  border-radius: 16px;
}

section.OurTeam {
  background: linear-gradient(
    105.55deg,
    rgba(220, 31, 255, 0.1) -53%,
    rgba(39, 170, 225, 0.1) 100%
  );
  background-color: #080b2d;
  padding: 150px 0px;
  background-image: url(../../../public/assets/images/TeamBack.png);
  background-repeat: no-repeat;
  background-size: cover;
}

section.OurTeam .our-team {
  position: relative;
}

section.OurTeam .our-team .team-content {
  position: absolute;
  bottom: 0px;
  background: rgba(0, 45, 191, 0.12);
  border: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(12px);
  border-radius: 8px;
  padding: 20px;
  min-height: 237px;
  bottom: 30px;
  z-index: 9999;
  left: 50%;
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: 80%;
}

section.OurTeam .our-team .team-content .title {
  color: #fff;
  font-size: 30px;
  margin-bottom: 10px;
}

section.OurTeam .our-team .team-content .post {
  color: #fff;
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 600;
  display: block;
}

section.OurTeam .our-team .team-content p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 400;
}

section.OurTeam .our-team .team-content ul li {
  margin-right: 15px;
}

.owl-dots {
  margin-top: 30px !important;
  align-items: center;
  display: flex;
  justify-content: center;
}

.owl-dots .owl-dot span {
  background: #869791 !important;
}

.owl-dots .owl-dot.active span {
  background: #fff !important;
  width: 15px !important;
  height: 15px !important;
}

section.Partners {
  background: linear-gradient(
    105.55deg,
    rgba(220, 31, 255, 0.1) -53%,
    rgba(39, 170, 225, 0.1) 100%
  );
  background-color: #080b2d;
  padding: 150px 0px;
  background-image: url(../../../public/assets/images/Partners.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

section.FAQ {
  background: linear-gradient(
    105.55deg,
    rgba(220, 31, 255, 0.1) -53%,
    rgba(39, 170, 225, 0.1) 100%
  );
  background-color: #080b2d;
  padding: 150px 0px;
  background-image: url(../../../public/assets/images/FAQBack.png);
  background-repeat: no-repeat;
  /* background-position: bottom; */
  background-size: cover;
}

section.FAQ .custom-accor .accordion-button {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;
  text-transform: capitalize;
  color: #fff;
  /* background-color: #080b2d; */
  background: none;
  box-shadow: none;
  padding: 18px 50px;
  border-radius: 20px !important;
}

section.FAQ .custom-accor .accordion-button::after {
  color: #fff !important;
  background-image: url(../../../public/assets/images/arrowdown.png);
}

section.FAQ .custom-accor .accordion-item {
  border: none;
  /* background-color: #080b2d; */
  margin-bottom: 20px;
  border-radius: 20px;
  background: rgba(0, 170, 255, 0.1);
}

section.FAQ .custom-accor .accordion-body {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  padding: 18px 50px;
  text-transform: capitalize;

  color: #ffffff;
}

section.FAQ .custom-accor .accordion-button:not(.collapsed) {
  /* background: none; */
  /* background-color: #080b2d; */
  color: #00aaff;
  background: none;
}

.avatar {
  transform: translatex(0px);
  animation: float 5s ease-in-out infinite;
}


.navbar-toggler {
  background-color: white !important;
}

.timeline-tri {
}

.footer {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #000037;
}

.footer-divider {
  color: white;
}

.footer-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
}

.avatartwo {
  transform: translatey(0px);
  animation: floattwo 6s ease-in-out infinite;
}

.footer-ul {
  padding: 0px!important;
}

.footer-ul li {
  list-style-type: none;
  color: white;
  padding: 7px;
}

.footer-ul li a {
  list-style-type: none;
  color: white;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
 
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatex(0px);
  }

  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatex(-80px);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatex(0px);
  }
}

@keyframes floattwo {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }

  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-80px);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

@media (min-width: 375px) and (max-width: 500px) {
  .customdataa {
    position: absolute;
    bottom: 27px;
    z-index: 9999;
    left: 45% !important;
    -moz-transform: translate(-65%, 0);
    -ms-transform: translate(-65%, 0);
    -o-transform: translate(-65%, 0);
    -webkit-transform: translate(-65%, 0);
    transform: translate(-65%, 0);
  }

  .blueheadingtwor {
    font-size: 10px !important;
  }

  .description {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 767px) {
  .timeline-block-left .timeline-content {
    background-image: url(../../../public/assets/images/union_left_mobile.svg);
    background-repeat: no-repeat;
    background-size: 80% 140px !important;
    height: 160px !important;
    text-align: start;
    position: relative;
    padding-top: 0px;
    left: 0px;
  }
  .blueheadingtwor {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 10px!important;
    line-height: 45%;
    color: #00aaff;
}
.description {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 9px!important;
  line-height: 160%;
  color: #ffffff;
}
  .timeline-block-right .timeline-content {
    background-image: url(../../../public/assets/images/union_right_mobile.svg);
    background-repeat: no-repeat;
    background-size: 80% 140px !important;
    height: 160px !important;
    text-align: start;
    position: relative;
    padding-top: 0px;
    left: 30px;
  }

  .timeline-block-right .timeline-content .data {
    padding-left: 12px !important;
    padding-right: 32px !important;
  }

  .timeline-block-left .timeline-content .data {
    padding-left: 10px !important;
    padding-right: 36px !important;
  }

  .timeline-block-left .timeline-content .data {
    margin-top: -10px;
  }

  .timeline-block-right .timeline-content .data {
    margin-top: -10px;
  }

  .customdataa {
    position: absolute;
    bottom: 27px;
    z-index: 9999;
    left: 49% !important;
    -moz-transform: translate(-65%, 0);
    -ms-transform: translate(-65%, 0);
    -o-transform: translate(-65%, 0);
    -webkit-transform: translate(-65%, 0);
    transform: translate(-65%, 0);
  }

  .customdata {
    position: absolute;
    bottom: 27px;
    z-index: 9999;
    left: 37% !important;
    -moz-transform: translate(-65%, 0);
    -ms-transform: translate(-65%, 0);
    -o-transform: translate(-65%, 0);
    -webkit-transform: translate(-65%, 0);
    transform: translate(-65%, 0);
  }

  .banner-sale {
    position: relative;
    border: 1px solid transparent;
    border-radius: 16px;
    background: linear-gradient(to right, #07091f, #0c213c);
    background-clip: padding-box;
    padding: 5px;
  }

  section.Slider img {
    height: 200px;
    object-fit: cover;
  }

  .avatar {
    display: none;
  }

  .avatarthree {
    display: none;
  }

  .anim1 {
    display: none;
  }

  /* section.AboutUs {
        background-image: url(../../../public/assets/images/aboutusback.png);
        background-repeat: no-repeat;
        height: 800px;
        padding: 40px 0px 40px;
        background-color: #080b2d;
        background-size: cover;
      } */
  section.Problems {
    padding: 50px 0px;
  }

  section.AboutUs {
    padding: 50px 0px;
  }

  .solution-m {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }

  section.Buy {
    padding: 50px 0;
  }

  section.RoadMap {
    padding: 50px 0;
  }

  .col-dash-2 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .col-dash-5 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .col-dash-7 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .col-dash-6 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .col-dash-4 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .col-dash-8 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .col-dash-6 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .col-dash-12 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .solution-content {
    padding: 25px;
  }

  .Box {
    text-align: start !important;
  }

  .coin-md {
    display: none;
  }

  .solution-banner {
    padding: 25px;
  }

  .descriptionTwo {
    font-size: 16px !important;
  }

  .content-card {
    padding: 25px;
  }

  section.OurTeam {
    padding: 50px 0px;
  }

  section.FAQ .custom-accor .accordion-button {
    font-size: 18px;
    padding: 18px 20px;
  }

  section.FAQ .custom-accor .accordion-body {
    font-size: 16px;
    padding: 18px 20px;
  }

  .whiteheadingtwo {
    font-size: 32px !important;
  }

  section.Partners {
    padding: 50px 0;
  }

  .custom-accor::after {
  }

  section.Sale .countdown > div {
    margin-right: 0px !important;
    margin-left: 12px !important;
  }

  .timeline-block-left {
    float: none;
    direction: inherit;
  }

  .whiteheadingtwos {
    font-size: 12px !important;
  }

  .whiteheadingtwob {
    font-size: 20px !important;
  }

  section.Sale .countdown h1 {
    border: 1px dashed #00aaff;
    border-radius: 10px;
    color: #fff;
    padding: 10px;
    width: 55px;
    height: 55px;
    margin: 0px;
    margin-bottom: 0px;
    font-size: 20px;
    color: #00aaff;
    font-weight: 500;
    margin-bottom: 15px;
  }

  section.Sale .countdown h4 {
    font-size: 14px;
    color: #00aaff;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .eth-list img {
    width: 20px;
    margin-left: 2px;
  }

  ul.coins li {
    background: #ffffff;
    border-radius: 6px;
    padding: 4px;
    width: 22%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 5px 10px;
  }
}
.social-links li{
  list-style-type: none;
  padding: 5px 0px;
}
@media screen and (max-width: 1199px) {
  section.OurTeam .our-team .team-content {
    position: absolute;
    bottom: 0px;
    background: rgba(0, 45, 191, 0.12);
    border: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(12px);
    border-radius: 8px;
    padding: 20px;
    min-height: 0px;
    bottom: 30px;
    z-index: 9999;
    left: 50%;
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 80%;
  }

  section.OurTeam .our-team .team-content .title {
    color: #fff;
    font-size: 15px;
    margin-bottom: 5px;
  }

  section.OurTeam .our-team .team-content .post {
    color: #fff;
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 600;
    display: block;
  }

  section.OurTeam .our-team .team-content p {
    color: #fff;
    font-size: 11px;
    margin-bottom: 20px;
    font-weight: 400;
  }

  .social img {
    width: 20px;
  }

  .timeline-block-left .timeline-content {
    background-image: url(../../../public/assets/images/union_left_mobile.svg);
    background-repeat: no-repeat;
    background-size: 80% 160px;
    height: 160px;
    text-align: start;
    position: relative;
    padding-top: 0px;
  }

  .timeline-block-right .timeline-content {
    background-image: url(../../../public/assets/images/union_right_mobile.svg);
    background-repeat: no-repeat;
    background-size: 80% 160px;
    height: 160px;
    text-align: start;
    position: relative;
    padding-top: 0px;
  }
}

@media screen and (max-width: 991px) {
  .customdataa {
    position: absolute;
    bottom: 27px;
    z-index: 9999;
    left: 42% !important;
    -moz-transform: translate(-65%, 0);
    -ms-transform: translate(-65%, 0);
    -o-transform: translate(-65%, 0);
    -webkit-transform: translate(-65%, 0);
    transform: translate(-65%, 0);
  }

  .timeline-block-left .timeline-content {
    background-image: url(../../../public/assets/images/union_left_mobile.svg);
    background-repeat: no-repeat;
    background-size: 80% 160px;
    height: 160px;
    text-align: start;
    position: relative;
    padding-top: 0px;
  }

  .timeline-block-right .timeline-content {
    background-image: url(../../../public/assets/images/union_right_mobile.svg);
    background-repeat: no-repeat;
    background-size: 80% 160px;
    height: 160px;
    text-align: start;
    position: relative;
    padding-top: 0px;
  }

  .timeline-block-right .timeline-content .data {
    padding-left: 40px;
    padding-right: 70px;
  }

  .timeline-block-left .timeline-content .data {
    padding-left: 40px;
    padding-right: 90px;
  }

  .blueheadingtwor {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;
    color: #00aaff;
  }

  .description {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 160%;
    color: #ffffff;
  }

  .blueheading {
    font-family: "Space Grotesk", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 49px !important;

    color: #00aaff;
  }

  .whiteheading {
    font-family: "Space Grotesk", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px !important;
    color: #fff;
  }

  ul.coins li {
    background: #ffffff;
    border-radius: 6px;
    padding: 9px;
    width: 29%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 5px 10px;
  }

  .salecontent li {
    font-family: "Prompt";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
    color: #000000;
  }

  .blueheadingtwo {
    font-family: "Prompt" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 29px !important;
    line-height: 100% !important;
    color: #00aaff;
  }

  .customdata {
    position: absolute;
    bottom: 27px;
    z-index: 9999;
    left: 32% !important;
    -moz-transform: translate(-65%, 0);
    -ms-transform: translate(-65%, 0);
    -o-transform: translate(-65%, 0);
    -webkit-transform: translate(-65%, 0);
    transform: translate(-65%, 0);
  }

  .footer-text p {
    text-align: center;
  }

  .policy {
    justify-content: center;
  }

  .footer-img {
    display: flex !important;
    justify-content: center !important;
  }
  .footer-ul{
 
    margin-top: 45px;
}

  /* .custom-accor {
        position: relative;
        border: 1px solid transparent;
        border-radius: 16px;
        background: #07071C;
        background-clip: padding-box;
        height: 160px;
        width: 100%;

        padding-top: 40px;
        direction: inherit;
        left: 0px;
    } */

  .custom-accor::after {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    background: #6b91e0;
    content: "";
    z-index: -1;
    border-radius: 16px;
  }

  .solution-content {
    border-radius: 25px;
  }
}
@media screen and (max-width: 992px) {
  .custom-nav a.nav-link {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    padding: 8px 0px !important;
  }
  .content-card {
    height: auto;
  }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0px!important;
    margin-top: var(--bs-dropdown-spacer);
}
.social-links {
  margin-top: 50px;
}
}
@media (min-width: 992px) and (max-width: 1199px) {
  /* CSS rules specific to devices with screen widths between 1400px and 1199px */
  /* For example: */
  .content-card {
    position: relative;
    border: 1px solid transparent;
    border-radius: 16px;
    background: black;
    background-clip: border-box;
    background-clip: padding-box;
    padding: 40px;
    
  }

  body {
    font-size: 16px;
    background-color: #f2f2f2;
  }

  .blueheadingtwor {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    /* line-height: 100%; */
    color: #00aaff !important;
  }

  .description {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px !important;
    /* line-height: 160%; */
    color: #ffffff;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  /* CSS rules specific to devices with screen widths between 1400px and 1199px */
  /* For example: */
  body {
    font-size: 16px;
    background-color: #f2f2f2;
  }

  .footer-img {
   
  }

  .description {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px !important;
    /* line-height: 160%; */
    color: #ffffff;
  }

  .timeline-block-left .timeline-content {
    background-image: url(../../../public/assets/images/UnionLeft.png);
    background-repeat: no-repeat;
    background-size: 80% 160px;
    height: 160px;
    text-align: start;
    position: relative;
    padding-top: 0px;
  }

  .timeline-block-right .timeline-content {
    background-image: url(../../../public/assets/images/Union.png);
    background-repeat: no-repeat;
    background-size: 80% 160px;
    height: 160px;
    text-align: start;
    position: relative;
    padding-top: 0px;
  }

  .blueheadingtwor {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    /* line-height: 100%; */
    color: #00aaff !important;
  }
}
