/* =========== Google Fonts ============ */
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* =============== Globals ============== */
* {
  font-family: 'Prompt', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  margin-bottom: 0px;
}

:root {
  --blue: #2a2185;
  --white: #fff;
  --gray: #f5f5f5;
  --black1: #222;
  --black2: #999;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}

.container {
  position: relative;
  width: 100%;
}

/* =============== Navigation ================ */
.navigation {
  position: fixed;
  width: 300px;
  height: 100%;
  background: #19194B;

  transition: 0.5s;
  overflow: hidden;
}

.navigation.active {
  width: 80px;
}

.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.navigation ul li {
  position: relative;
  width: 100%;
  list-style: none;

}

.navigation ul li:hover,
.navigation ul li.hovered {
  color: white;
}

.navigation ul li:nth-child(1) {
  margin-bottom: 40px;
  pointer-events: none;
}

.navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: var(--white);
}

.navigation ul li:hover a,
.navigation ul li.hovered a {
  color: var(--blue);
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 75px;
  text-align: center;
}

.navigation ul li a .icon ion-icon {
  font-size: 1.75rem;
}

.navigation ul li a .title {
  position: relative;
  display: block;
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
}

/* --------- curve outside ---------- */
/* .navigation ul li:hover a::before,
.navigation ul li.hovered a::before {
  content: "";
  position: absolute;
  right: 0;
  top: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px 35px 0 10px var(--white);
  pointer-events: none;
} */
/* .navigation ul li:hover a::after,
.navigation ul li.hovered a::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px -35px 0 10px var(--white);
  pointer-events: none;
} */

/* ===================== Main ===================== */
.main {
  position: absolute;
  width: calc(100% - 300px);
  left: 300px;
  min-height: 100vh;
  background-color: rgba(0, 0, 55, 1);
  transition: 0.5s;
}

.main.active {
  width: calc(100% - 80px);
  left: 80px;
}

.topbar {
  /* width: 95%; */
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #19194B;
  padding: 0 10px;
  margin-left: 28px;
  margin-right: 28px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.topbar ul {
  margin: 0px;
  display: flex;

}

.topbar ul li {
  list-style-type: none;

}

.topbar ul li a {
  color: white;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  font-weight: 400;
}

.buy-b {
  color: white;
  background: #00AAFF;
  border-radius: 8px;
  padding: 14px 30px;
  margin-right: 20px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: none;
  color: white;
}

.buy-b:hover {
  color: white;
}

.buy-b a {
  text-decoration: none;
  color: white;
}

.button-sec {
  display: flex;
  align-items: center;

}

.toggle {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  cursor: pointer;
}

.search {
  position: relative;
  width: 400px;
  margin: 0 10px;
}

.search label {
  position: relative;
  width: 100%;
}

.search label input {
  width: 100%;
  height: 40px;
  border-radius: 40px;
  padding: 5px 20px;
  padding-left: 35px;
  font-size: 18px;
  outline: none;
  border: 1px solid var(--black2);
}

.search label ion-icon {
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 1.2rem;
}

.user img {
  width: 45px;
  border-radius: 50%;
  margin-right: 15px;
}

.user {
  display: flex;
  align-items: center;
}

.user p {
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

/* ======================= Cards ====================== */
.cardBox {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.cardBox .card {
  position: relative;
  background: var(--white);
  padding: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
}

.cardBox .card .numbers {
  position: relative;
  font-weight: 500;
  font-size: 2.5rem;
  color: var(--blue);
}

.cardBox .card .cardName {
  color: var(--black2);
  font-size: 1.1rem;
  margin-top: 5px;
}

.cardBox .card .iconBx {
  font-size: 3.5rem;
  color: var(--black2);
}

.cardBox .card:hover {
  background: var(--blue);
}

.cardBox .card:hover .numbers,
.cardBox .card:hover .cardName,
.cardBox .card:hover .iconBx {
  color: var(--white);
}

/* ================== Order Details List ============== */
.details {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
  /* margin-top: 10px; */
}

.details .recentOrders {
  position: relative;
  display: grid;
  min-height: 500px;
  background: var(--white);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.details .cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cardHeader h2 {
  font-weight: 600;
  color: var(--blue);
}

.cardHeader .btn {
  position: relative;
  padding: 5px 10px;
  background: var(--blue);
  text-decoration: none;
  color: var(--white);
  border-radius: 6px;
}

.details table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.details table thead td {
  font-weight: 600;
}

.details .recentOrders table tr {
  color: var(--black1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.details .recentOrders table tr:last-child {
  border-bottom: none;
}

.details .recentOrders table tbody tr:hover {
  background: var(--blue);
  color: var(--white);
}

.details .recentOrders table tr td {
  padding: 10px;
}

.details .recentOrders table tr td:last-child {
  text-align: end;
}

.details .recentOrders table tr td:nth-child(2) {
  text-align: end;
}

.details .recentOrders table tr td:nth-child(3) {
  text-align: center;
}

.status.delivered {
  padding: 2px 4px;
  background: #8de02c;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.pending {
  padding: 2px 4px;
  background: #e9b10a;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.return {
  padding: 2px 4px;
  background: #f00;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.inProgress {
  padding: 2px 4px;
  background: #1795ce;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.recentCustomers {
  position: relative;
  display: grid;
  min-height: 500px;
  padding: 20px;
  background: var(--white);
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.recentCustomers .imgBx {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  overflow: hidden;
}

.recentCustomers .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recentCustomers table tr td {
  padding: 12px 10px;
}

.recentCustomers table tr td h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2rem;
}

.recentCustomers table tr td h4 span {
  font-size: 14px;
  color: var(--black2);
}

.recentCustomers table tr:hover {
  background: var(--blue);
  color: var(--white);
}

.recentCustomers table tr:hover td h4 span {
  color: var(--white);
}

/* ====================== Responsive Design ========================== */
@media (max-width: 991px) {
  .navigation {
    left: -300px;
  }
  .break{
    display: block!important;
  }
  .navigation.active {
    width: 300px;
    left: 0;
  }
  .inner-succeed p {
    font-size: 12.36px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    word-break: break-all !important;
  }
  .proo h2 {
    font-weight: 500 !important;
    font-size: 12.48px !important;
    line-height: 24.48px !important;
    color: white !important;
    margin-bottom: 0px !important;
    word-break: break-all;
  }

  .main {
    width: 100%;
    left: 0;
  }

  .main.active {
    left: 300px;
  }

  .cardBox {
    grid-template-columns: repeat(2, 1fr);
  }

  .topbar {
    display: none;
  }

  .confirm-main {
    display: block!important;
    align-items: center;
    position: static !important;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 24px;
  }

  .select {
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 30px;
    margin-bottom: 30px !important;
    margin-top: 30px !important;
  }

  input {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    border: 1px solid #00AAFF;
    border-radius: 10px;
    margin-top: 0px !important;
    background-color: #19194B;
    width: 100%;
  }

  .input-a p {
    position: absolute;
    color: #D42014;
    right: 15px;
    top: 37% !important;
  }

  .amount-num {
    border: 1px solid #00AAFF;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    padding: 20px;
    color: white;
    border-radius: 10px;

    margin-bottom: 20px;
  }
  
  .swap {
   display: none;
  }
  .confirm-main h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 30px;
  }

}

@media (max-width: 768px) {
  .details {
    grid-template-columns: 1fr;
  }

  .recentOrders {
    overflow-x: auto;
  }

  .status.inProgress {
    white-space: nowrap;
  }

  .confirm-main {
    display: block;
    align-items: center;
    position: static !important;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 24px;

  }

  .amount-num {
    border: 1px solid #00AAFF;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    padding: 20px;
    color: white;
    border-radius: 10px;

    margin-bottom: 20px;
  }
  .confirm-main h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 30px;
  }

}

@media (max-width: 480px) {
  .cardBox {
    grid-template-columns: repeat(1, 1fr);
  }

  .cardHeader h2 {
    font-size: 20px;
  }

  .user {
    min-width: 40px;
  }

  .navigation {
    width: 100%;
    left: -100%;
    z-index: 1000;
  }

  .navigation.active {
    width: 100%;
    left: 0;
  }

  .toggle {
    z-index: 10001;
  }

  .main.active .toggle {
    color: #fff;
    position: fixed;
    right: 0;
    left: initial;
  }

  .confirm-main {
    display: block;
    align-items: center;
    position: static !important;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 24px;

  }

  .amount-num {
    border: 1px solid #00AAFF;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    padding: 20px;
    color: white;
    border-radius: 10px;

    margin-bottom: 20px;
  }
  .confirm-main h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 30px;
  }

}

.brand {
  display: flex;
  justify-content: center;
  padding: 29px;
}

.buy {
  padding: 16px;
  border-top: 1px solid #30305D;
  border-bottom: 1px solid #30305D;
  width: 100%;
  background-color: #19194B;
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 36px;
}

.links {
  display: flex;
  align-items: center;
  color: white;
  font-weight: 500;
  font-size: 18px;
  padding: 19px;
  margin-top: 20px;
  border-left: 10px solid #19194B;
}




.links img {
  margin-right: 25px;
}

.inner-main {
  padding: 25px;
  background-color: #19194B;
  margin-top: 32px;
  margin-left: 28px;
  margin-right: 28px;
  border-radius: 10px;
}

.main-heading {
  color: white;
  font-weight: 600;
  font-size: 32px;
  line-height: 100%;
  margin-bottom: 29px;
}

.sub-heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #00AAFF;
  margin-bottom: 20px;
}

.token-bar {
  background: #212160;
  border-radius: 10px;
  color: white;
  padding: 20px;
  margin-bottom: 20px;
}

.select {

  color: white;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}

input {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  border: 1px solid #00AAFF;
  border-radius: 10px;
  margin-top: 49px;
  background-color: #19194B;
  width: 100%;
  font-size: 20px;
}

input::placeholder {
  color: white !important;
  position: absolute;
  z-index: 9999999999;
}

.input-a {
  position: relative;
}

.input-a p {
  position: absolute;
  color: #D42014;
  right: 15px;
  top: 62%;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

h6 {
  margin-bottom: 0px;
}

.amount h4 {
  color: white;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.amount p {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */


  color: #D52014;
}

.amount-num {
  border: 1px solid #00AAFF;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  padding: 20px;
  color: white;
  border-radius: 10px;

}

.input-m textField {
  border-color: #00AAFF !important;
}

.pay {
  padding: 25px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  background: #212160;
  color: white;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 15px;
}

.confirm-main {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 23px;
  left: 57%;
}

.confirm-main h6 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
}

.confirm-main a {
  padding: 13px 34px;
  gap: 10px;
  background: #00AAFF;
  border-radius: 8px;
  color: white;
  text-decoration: none;
  margin-left: 20px;
}

.swap {
  position: relative;
  margin-bottom: 25px;
}

.swap img {
  position: absolute;
  left: 40%;
}

.mobile-links {
  background-color: #19194B;
}

.mobile-links ul {
  margin: 0px;
  display: block;
}

.mobile-links ul li {
  list-style-type: none;
  padding: 10px 30px;
  text-align: center;
}

.mobile-links ul li:hover {
  background-color: #2a2185;
}

.mobile-links ul li a {
  color: white;
  font-size: 18px;
  font-weight: 400;
  transition: 3s;
  text-decoration: none;
}

.mobile-nav {
  display: block;
  background-color: #19194B;
  padding: 30px;
}

p {
  margin: 0px !important;
}

h4 {
  margin: 0px !important;
}

@media (min-width: 992px) and (max-width: 1239px) {
  .topbar ul li a {
    color: white;
    text-decoration: none;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 11px !important;
  }

  .buy-b {
    color: white;
    background: #00AAFF;
    border-radius: 8px;
    padding: 6px 16px;
    margin-right: 20px;
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
  }

  .user img {
    width: 36px;
    border-radius: 50%;
    margin-right: 9px;
  }

  .user p {
    color: white;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
  }

  .col-lan-5 {
    padding: 0px;
  }

  /* .confirm-main {
    display: flex;
    align-items: center;
    position: static!important;
    justify-content: center;
    margin-top: 20px;
    
  } */
  .confirm-main h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #FFFFFF;
    
  }

  .confirm-main a {
    padding: 11px 15px;
    gap: 10px;
    background: #00AAFF;
    border-radius: 8px;
    color: white;
    text-decoration: none;
    margin-left: 20px;
    font-size: 15px;
  }

  .input-a {
    position: relative;
    margin-bottom: 29px !important;
  }
}

@media (min-width: 500px) and (max-width: 767px) {
  .inner-main {
    padding: 0px;
    background-color: #19194B;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 10px;
    padding-top: 20px;
  }

  .main-heading {
    color: white;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    margin-bottom: 29px;
  }

  .sub-heading {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #00AAFF;
    margin-bottom: 20px;
  }

  .token-bar p {
    font-size: 13px;
  }

  .select {
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 30px;
    margin-bottom: 30px !important;
    margin-top: 30px !important;
  }

  .pay {
    padding: 25px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    background: #212160;
    color: white;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    margin-bottom: 15px;
  }

  .amount-num {
    border: 1px solid #00AAFF;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    padding: 20px;
    color: white;
    border-radius: 10px;
  }

  .col-lan-5 {
    padding: 10px;
  }

  .col-lan-2 {
    padding: 10px;
  }

  .col-lan-6 {
    padding: 10px;
  }

  .col-lan-4 {
    padding: 0px 10px !important;
  }

  .col-lan-12 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .confirm-main {
    display: flex;
    align-items: center;
    position: static !important;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 24px;

  }

  .cus {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .confirm-main {
    display: block;
    align-items: center;
    position: static !important;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 24px;
  }

  .confirm-main h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 30px;
  }


}

@media (min-width: 375px) and (max-width: 499px) {
  .inner-main {
    padding: 0px;
    background-color: #19194B;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 10px;
    padding-top: 20px;
  }

  .main-heading {
    color: white;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    margin-bottom: 29px;
  }

  .sub-heading {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #00AAFF;
    margin-bottom: 20px;
  }

  .token-bar p {
    font-size: 12px;
  }

  .select {
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 30px;
    margin-bottom: 30px !important;
    margin-top: 30px !important;
  }

  .pay {
    padding: 25px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    background: #212160;
    color: white;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    margin-bottom: 15px;
  }

  .amount-num {
    border: 1px solid #00AAFF;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    padding: 20px;
    color: white;
    border-radius: 10px;
  }

  .col-lan-5 {
    padding: 10px;
  }

  .col-lan-2 {
    padding: 10px;
  }

  .col-lan-6 {
    padding: 10px;
  }

  .col-lan-12 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .col-lan-4 {
    padding: 0px 10px !important;
  }

  .confirm-main {
    display: block;
    align-items: center;
    position: static !important;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 24px;
  }

  .cus {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .p-sub-heading {
    font-weight: 600;
    font-size: 18px !important;
    line-height: 36px;
    color: white;
  }

  .p-des {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 160%;
    color: #C5C5C5;
    margin-bottom: 20px;
  }

  .checkbox {
    width: 4% !important;
  }
  .confirm-main h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 30px;
  }

}

.isActive {
  background-color: rgba(0, 169, 254, 1);
  border-left: 10px solid white;
}

.p-sub-heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: white;
}

.p-des {
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;

  /* identical to box height, or 29px */

  color: #C5C5C5;
  margin-bottom: 20px;
}

.progress {
  background-color: #212160 !important;
  margin-bottom: 10px;
  margin-top: 10px;
}

.checkbox {
  width: 2%;
  margin: 0px;
  margin-right: 15px;
  color: #1795ce;
  background-color: #1795ce;
}

input[type="checkbox"]:checked {
  background-color: #1795ce;
}

.confirm-b {
  background-color: #00AAFF;
  padding: 15px 40px;
  border-radius: 10px;
  text-decoration: none;
  color: white !important;
  margin-bottom: 30px !important;
}

.table th {
  padding: 20px !important;
}
.hash{
  color: white;
  font-weight: 500;
  font-size: 18px;
  line-height: 28.8px;
  word-break: break-all;
}
.break{
  display: none;
}
.succeed{
  margin-top: 50px;
}
.succeed h1{
   font-weight: 500;
   font-size: 32px;
   line-height: 32px;
   margin-bottom: 20px;
   color: white;
}
.succeed p{
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: white;
  margin-bottom: 10px;
  word-break: break-all
}
.inner-succeed{
  padding: 20px;
  background-color: #212160;
  border-radius: 10px;
  margin-top: 20px;
}
.proo h2{
  font-weight: 600;
  font-size: 24.48px;
  line-height: 24.48px;
  color: white!important;
  margin-bottom: 0px!important;
  word-break: break-all
}
.inner-succeed p{
  font-size: 18.36px;
  font-weight: 500;
  line-height: 18px;
  word-break: break-all
}
.s-h2{
  font-weight: 600;
  font-size: 24.48px;
  line-height: 24.48px;
  color: white!important;
}
.status{
  font-size: 18.36px;
  line-height: 18.36px;
  font-weight: 500;
  background-color: #00AAFF;
  padding: 7px 15px;
  border-radius: 15px;
}
